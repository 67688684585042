import { contactSubmit } from './contact.js';
import { supplies } from './supplies.js';

supplies.init();

if (document.getElementById('contactForm')) {
	const file_upload = document.getElementById("file-upload");
	if (file_upload) {
		file_upload.addEventListener("change", () => {
			const file_upload_text = document.getElementById("file_upload_text");

			file_upload_text.value = file_upload.files[0].name;
			console.log(file_upload.files);

			for (let i = 1; i < file_upload.files.length; i++) {
				file_upload_text.value += `, ${file_upload.files[i].name}`;
				console.log(file_upload.files[i].value);
			}
		});
	}
	contactSubmit();
}

if(document.getElementById('review_caro')){
	//let caroInner = document.querySelector('#review_caro .caro_inner');
	let viewMoreLinks = document.querySelectorAll('#review_caro .view_more');
	let viewLessLinks = document.querySelectorAll('#review_caro .view_less');
	viewMoreLinks.forEach(viewMoreLink => {
		viewMoreLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.add('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'auto';
		});
	});
	viewLessLinks.forEach(viewLessLink => {
		viewLessLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.remove('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'inherit';
		});
	});
}